// @ts-nocheck
import dynamic from 'next/dynamic' // (if using Next.js or use own dynamic loader)
const RDWEditor = dynamic(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), {
    ssr: false,
})
import { EditorState, convertToRaw } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import * as draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './style.module.scss'
import { ErrorMessage } from '../ErrorMessage'

type Props = {
    className?: string
    error?: string
    value?: any

    onChange?: (newVal: any) => void
}

const Editor = ({ className, error, value, onChange }: Props) => {
    // Ensures editorState is initialized properly
    const editorState = value || EditorState.createEmpty()

    return (
        <div className={className}>
            <RDWEditor
                editorState={editorState}
                onEditorStateChange={(state) => onChange?.(state)}
                tabIndex="0"
                editorClassName={styles.editor}
                toolbarClassName={styles.toolbar}
                readOnly={!onChange}
            />
            <ErrorMessage>{error}</ErrorMessage>
        </div>
    )
}

const draftjsToHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()))
}

const htmlToDraftjs = (html) => {
    const contentState = stateFromHTML(html)
    // https://github.com/jpuri/react-draft-wysiwyg/issues/609#issuecomment-365991707
    // const contentBlock = htmlToDraft(html)
    // const contentState = ContentState.createFromBlockArray(
    //     contentBlock.contentBlocks
    // )
    const editorState = EditorState.createWithContent(contentState)
    return editorState
}

export { Editor, draftjsToHtml, htmlToDraftjs }
