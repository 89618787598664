import { withSession } from 'lib/hoc/withSession'

import { Card, Button, PageHeader } from 'ds/components'
import { CreateAnythingButton } from 'lib/common/CreateAnythingButton'
import { AccountsReceivablesDashboard } from './AccountsReceivablesDashboard'

function Dashboard({ session }) {
    console.log('session', session.has_invoice)
    return (
        <div className="container-fluid">
            <PageHeader title="Dashboard" action={<CreateAnythingButton />} />

            {session.has_invoice ? (
                <AccountsReceivablesDashboard />
            ) : (
                <Card>
                    <h2>Empieza a cobrar con Savio</h2>
                    <p>Listo para cobrar - empieza con tu primer cobro.</p>
                    <div className="inline inline-md stacked-md">
                        <Button href="/invoices/create">Crear cobro</Button>
                    </div>
                </Card>
            )}
        </div>
    )
}

export default withSession(Dashboard)
