import { KeyValue } from 'ds/components/KeyValue'
import { OptionButton, SelectButtons } from 'ds/components/SelectButtons'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { reportService } from 'lib/services/reportService'
import { castDecimal } from 'lib/utils/castDecimal'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Tooltip } from 'ds/components'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'
import { useLocalStorage } from 'usehooks-ts'

type Props = {
    organization_id: number | null
    dateParam: string
    currency: string | undefined
    start: Date | null
    end: Date | null
}

export const AmountDueByProductCategory = ({
    organization_id,
    dateParam,
    currency,
    start,
    end,
}: Props) => {
    const [productGroupBy, setProductGroupBy] = useLocalStorage<string>(
        'productGroupBy',
        'product'
    )

    const queryParams = useMemo(
        () => ({
            organization_id,
            currency,
            dateParam,
            start,
            end,
            groupBy: productGroupBy,
        }),
        [organization_id, dateParam, currency, start, end, productGroupBy]
    )

    const { isLoading, error, data } = useQuery({
        queryKey: ['receivablesByProduct', queryParams],
        queryFn: () => reportService.getReceivablesByProduct(queryParams),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const { rows, total } = useMemo(() => {
        const receivablesByProduct = data?.receivablesByProduct || []

        const rows = receivablesByProduct.sort((a, b) => {
            return b.amount_due - a.amount_due
        })

        const total = rows.reduce((acc, row) => acc.plus(row.amount_due), castDecimal(0))

        return { rows, total }
    }, [data])

    if (isLoading) return <Spinner />
    if (errorMessage)
        return <ErrorBanner>Ha ocurrido un error: {errorMessage}</ErrorBanner>

    return (
        <Card>
            <div>
                <h3 className="inline inline-xs">Cobranza por Producto</h3>
                <div className="inline">
                    <Tooltip trigger={<ion-icon name="help-circle-outline" />}>
                        Montos no incluyen impuestos.
                    </Tooltip>
                </div>
            </div>
            <div style={{ height: '368px', overflow: 'scroll' }}>
                <div className="stacked-sm">
                    <SelectButtons
                        value={productGroupBy}
                        onChange={(value) => {
                            setProductGroupBy(value)
                            localStorage.setItem('productGroupBy', value)
                        }}
                    >
                        <OptionButton value="product">Producto</OptionButton>
                        <OptionButton value="category">Categoría</OptionButton>
                    </SelectButtons>
                </div>
                {rows.map((row) => {
                    return (
                        <div key={row.product_id || row.category_id}>
                            <KeyValue title={row.name} right>
                                {formatCurrency(row.amount_due, 0)}
                            </KeyValue>
                        </div>
                    )
                })}
                <KeyValue title="Total" right>
                    <strong>{formatCurrency(total.toNumber(), 0)}</strong>
                </KeyValue>
            </div>
        </Card>
    )
}
