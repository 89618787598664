import { Button } from 'ds/components/Button'
import { Card } from 'ds/components/Card'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { InvoiceTable } from 'lib/common/InvoiceTable'
import { invoiceService } from 'lib/services/invoiceService'
import { getManualPagination } from 'lib/utils/getManualPagination'
import { parseAsInteger, useQueryStates } from 'next-usequerystate'
import { stringify } from 'qs'
import { useQuery } from '@tanstack/react-query'

function InvoicesDue({ organization_id, currency, dateParam, start, end }) {
    const initFilter = {
        limit: parseAsInteger.withDefault(25),
        offset: parseAsInteger.withDefault(0),
    }
    const [filter, setFilter] = useQueryStates(initFilter, { shallow: false })

    const queryParams = {
        components: 'customer',
        date_range_param: dateParam,
        organization_id,
        currency,
        settled_state: 'not_settled',
        start,
        end,
        sort_by: '-amount_total',
        limit: filter.limit,
        offset: filter.offset,
    }
    const { isLoading, error, data, isPreviousData } = useQuery({
        queryKey: ['invoice', 'v2', stringify(queryParams)],
        queryFn: () => invoiceService.getInvoicesV2(queryParams)
    })

    if (isLoading) return <Spinner />
    if (error) return <ErrorBanner>Ha ocurrido un error: {error.message}</ErrorBanner>
    if (!data) return <ErrorBanner>Cobros no encontrados.</ErrorBanner>

    const { invoices, paginationMetadata } = data

    return (
        <Card>
            <div className="d-flex">
                <div className="flex-fill">
                    <h3>Por Cobrar del Periodo</h3>
                </div>
                <div>
                    <Button href="/invoices" variant="primaryText">
                        Cobranza →
                    </Button>
                </div>
            </div>
            <InvoiceTable
                isLoading={isPreviousData}
                invoices={invoices}
                onDownload={() =>
                    invoiceService.getInvoicesV2({
                        ...queryParams,
                        download: true,
                    })
                }
                manualPagination={getManualPagination({
                    paginationMetadata,
                    filter,
                    setFilter,
                })}
            />
        </Card>
    )
}

export { InvoicesDue }
