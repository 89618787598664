import { colors } from 'lib/utils/colors'
import { formatCurrency } from 'lib/utils/formatCurrency'
import {
    ResponsiveContainer,
    BarChart as RechartsBarChart,
    Tooltip,
    YAxis,
    XAxis,
    Bar,
} from 'recharts'

export function BarChart({ data, color }) {
    return (
        <ResponsiveContainer width="100%" height={250}>
            <RechartsBarChart data={data}>
                <XAxis dataKey="name" stroke={colors['neutral-500']} />
                <YAxis
                    width={100}
                    stroke={colors['neutral-500']}
                    tickFormatter={(v) => '$' + v.toLocaleString('es-mx')}
                    padding={{ top: 40 }}
                    domain={[0, 'auto']}
                />
                <Tooltip
                    formatter={(v) => formatCurrency(v || 0, 0)}
                    cursor={{ fill: '#fff' }}
                />
                {/* <Legend /> */}
                <Bar dataKey="value" name="Monto" fill={color} />
                {/* <Bar dataKey="value" fill="#82ca9d" /> */}
            </RechartsBarChart>
        </ResponsiveContainer>
    )
}
