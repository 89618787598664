import { Card } from 'ds/components/Card'
import { Kpi } from 'ds/components/Kpi'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { reportService } from 'lib/services/reportService'
import { useQuery } from '@tanstack/react-query'
import { getAmountDisplay } from './getAmountDisplay'
import { formatNumber } from 'lib/utils/formatNumber'
import { castDecimal } from 'lib/utils/castDecimal'

function ReceivablesAgingKpis({ currency, organization_id }) {
    const { isLoading, data, error } = useQuery({
        queryKey: ['receivables-aging', currency, String(organization_id)],
        queryFn: () => {
            return reportService.getReceivablesAging({
                currency: currency,
                organization_id,
            })
        },
    })
    if (isLoading) return <Spinner />
    if (error) return <ErrorBanner>Ha ocurrido un error: {error.message}</ErrorBanner>
    if (data.error) return <ErrorBanner>Ha ocurrido un error: {data.error}</ErrorBanner>

    const { receivablesAging } = data

    const percentOutstanding = castDecimal(receivablesAging.total_overdue)
        .dividedBy(castDecimal(receivablesAging.total_outstanding))
        .times(100)
        .toNumber()

    return (
        <Card>
            <div className="row center">
                <div className="col-lg-3 col-md-6">
                    <Kpi label="Por Cobrar">
                        {getAmountDisplay(receivablesAging.total_outstanding)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Vigente">
                        {getAmountDisplay(receivablesAging.within_due)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Vencido">
                        {getAmountDisplay(receivablesAging.total_overdue)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Porcentaje Vencido">
                        {formatNumber(percentOutstanding, 0)}%
                    </Kpi>
                </div>
            </div>
        </Card>
    )
}

export { ReceivablesAgingKpis }
