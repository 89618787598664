import { Card } from 'ds/components/Card'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { reportService } from 'lib/services/reportService'
import { useQuery } from '@tanstack/react-query'
import { BarChart } from 'lib/common/BarChart'
import { colors } from 'lib/utils/colors'
import { Button } from 'ds/components/Button'

function ReceivablesAgingChart({ currency, organization_id }) {
    const { isLoading, data, error } = useQuery({
        queryKey: ['receivables-aging', currency, String(organization_id)],

        queryFn: () => {
            return reportService.getReceivablesAging({
                currency: currency,
                organization_id,
            })
        }
    })
    if (isLoading) return <Spinner />
    if (error) return <ErrorBanner>Ha ocurrido un error: {error.message}</ErrorBanner>
    if (data.error) return <ErrorBanner>Ha ocurrido un error: {data.error}</ErrorBanner>

    const { receivablesAging } = data

    const chartData = []

    chartData.push({
        name: 'Vigente',
        value: Number(receivablesAging.within_due),
    })
    chartData.push({
        name: '1-30 Días',
        value: Number(receivablesAging['1to30d']),
    })
    chartData.push({
        name: '31-60 Días',
        value: Number(receivablesAging['31to60d']),
    })
    chartData.push({
        name: '61-90 Días',
        value: Number(receivablesAging['61to90d']),
    })
    chartData.push({
        name: '90+ Días',
        value: Number(receivablesAging.gt90d),
    })

    return (
        <Card>
            <div className="d-flex">
                <div className="flex-fill">
                    <h3>Cartera Vencida</h3>
                </div>
                <div>
                    <Button
                        href="/reports?type=ReceivablesAgingReport"
                        variant="primaryText"
                    >
                        Reporte →
                    </Button>
                </div>
            </div>
            <BarChart data={chartData} color={colors.red} />
        </Card>
    )
}

export { ReceivablesAgingChart }
