import { formatCurrency } from 'lib/utils/formatCurrency'
import { colors } from 'lib/utils/colors'
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer,
    Area,
} from 'recharts'
import { Card } from 'ds/components/Card'
import { Tooltip } from 'ds/components/Tooltip'

function RevenueChart({ data }) {
    return (
        <Card>
            <div>
                <h3 className="inline inline-xs">Cobranza </h3>
                <div className="inline">
                    <Tooltip trigger={<ion-icon name="help-circle-outline"></ion-icon>}>
                        Lo que debe entrar según las fecha de vencimiento contra los ingresos
                        actuales.
                    </Tooltip>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={250}>
                <ComposedChart data={data}>
                    <XAxis dataKey="month" stroke={colors['neutral-500']} />
                    <YAxis
                        width={100}
                        tickFormatter={(v) => '$' + v.toLocaleString('es-mx')}
                        padding={{ top: 40 }}
                        stroke={colors['neutral-500']}
                    />
                    <Line
                        type="monotone"
                        name="Por Cobrar"
                        dataKey="amountDue"
                        stroke={colors['primary-500']}
                        strokeWidth={1.5}
                        dot={false}
                    />
                    <Area
                        type="monotone"
                        name="Cobrado"
                        dataKey="amountSettled"
                        stroke={colors['success-300']}
                        fill={colors['success-300']}
                        strokeWidth={1.5}
                        dot={false}
                    />
                    <RechartsTooltip formatter={(v) => formatCurrency(v)} />
                    <Legend />
                </ComposedChart>
            </ResponsiveContainer>
        </Card>
    )
}

export { RevenueChart }
