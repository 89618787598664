import { KeyValue } from 'ds/components/KeyValue'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { reportService } from 'lib/services/reportService'
import { castDecimal } from 'lib/utils/castDecimal'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { useQuery } from '@tanstack/react-query'
import { Card } from 'ds/components'
import { useMemo } from 'react'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

type Props = {
    organization_id: number | null
    dateParam: string
    currency: string | undefined
    start: Date | null
    end: Date | null
}

export const AmountDueByCustomer = ({
    organization_id,
    dateParam,
    currency,
    start,
    end,
}: Props) => {
    const queryParams = useMemo(
        () => ({
            organization_id,
            dateParam,
            currency,
            start,
            end,
        }),
        [organization_id, dateParam, currency, start, end]
    )

    const { isLoading, error, data } = useQuery({
        queryKey: ['ReceivablesAgingReport', queryParams],
        queryFn: () => reportService.getReceivablesAgingByCustomer(queryParams),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const { rows, total } = useMemo(() => {
        const receivablesAgingByCustomer = data?.receivablesAgingByCustomer || []

        const rows = receivablesAgingByCustomer.sort((a, b) => {
            return Number(b.total_outstanding) - Number(a.total_outstanding)
        })

        const total = rows.reduce(
            (acc, row) => acc.plus(row.total_outstanding),
            castDecimal(0)
        )

        return { rows, total }
    }, [data])

    if (isLoading) return <Spinner />
    if (errorMessage)
        return <ErrorBanner>Ha ocurrido un error: {errorMessage}</ErrorBanner>

    return (
        <Card>
            <h3>Cobranza por Cliente</h3>
            <div style={{ height: '368px', overflow: 'scroll' }}>
                {rows.map((row) => (
                    <div key={row.customer_id}>
                        <KeyValue title={row.customer} right>
                            {formatCurrency(row.total_outstanding, 0)}
                        </KeyValue>
                    </div>
                ))}
                <KeyValue title="Total" right>
                    <strong>{formatCurrency(total.toNumber(), 0)}</strong>
                </KeyValue>
            </div>
        </Card>
    )
}
