/* eslint-disable react/no-multi-comp */
import styles from './style.module.scss'
import { formatCurrency } from 'lib/utils/formatCurrency'
import {
    ResponsiveContainer,
    PieChart as RechartsPieChart,
    Pie,
    Cell,
    Tooltip,
    Legend,
} from 'recharts'
import classNames from 'classnames'

const CustomTooltip = ({ active, payload }) => {
    if (active) {
        return (
            <div className={classNames(styles.content, 'inset-sm')}>
                {`${payload[0].name}: ${formatCurrency(payload[0].value, 0)}`}
            </div>
        )
    }
    return null
}

function PieChart({ data }) {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <RechartsPieChart>
                <Pie
                    data={data}
                    color="#000000"
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={100}
                    label={(entry) => formatCurrency(entry.value || 0, 0)}
                >
                    {data.map((item, index) => {
                        return <Cell key={`cell-${index}`} fill={item.color} />
                    })}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
            </RechartsPieChart>
        </ResponsiveContainer>
    )
}

export { PieChart }
