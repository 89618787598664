import { useQuery } from '@tanstack/react-query'
import { endOfMonth, formatDate, startOfMonth, subMonths } from 'date-fns'

import { ErrorBanner, Spinner } from 'ds/components'
import { RevenueChart } from 'lib/common/RevenueChart'

import { reportService } from 'lib/services/reportService'

export const DashboardRevenueChart = ({ currency, organization_id }) => {
    const { isLoading, error, data } = useQuery({
        queryKey: ['dashboard', String(currency), String(organization_id)],

        queryFn: () =>
            reportService.getPaymentTotals({
                currency,
                organization_id,
                start: startOfMonth(subMonths(new Date(), 12)),
                end: endOfMonth(new Date()),
            }),
    })
    if (isLoading) return <Spinner />
    if (error) return <ErrorBanner>Ha ocurrido un error: {error.message}</ErrorBanner>

    const { dueByMonth, settledByMonth } = data
    const revenueChartData = prepareData(dueByMonth, settledByMonth)

    return <RevenueChart data={revenueChartData} currency={currency} />
}

const prepareData = (dueByMonth, settledByMonth) => {
    const merged = []
    for (let i = 0; i < dueByMonth.length; i++) {
        merged.push({
            ...dueByMonth[i],
            ...settledByMonth.find(
                (itmInner) => itmInner['month'] === dueByMonth[i]['month']
            ),
        })

        merged[i].amountDue = Number(merged[i].amountDue || 0)
        merged[i].amountSettled = Number(merged[i].amountSettled || 0)
    }
    if (merged.length === 0) {
        merged.push({
            month: formatDate(new Date(), 'M/yyyy'),
            amountDue: 0,
            amountSettled: 0,
        })
    }
    return merged
}
