type GetTextMessageContentParams = {
    subject: string
    message: string
    fileUrls?: string[]
}

export const getTextMessageContent = ({
    subject,
    message,
    fileUrls,
}: GetTextMessageContentParams): string => {
    return `
    ${subject}
    ${message}
    ${fileUrls ? fileUrls.join('<br>') : ''}
    `
        .replace(/<br>/g, '%0a')
        .replace(/<p>/g, '%0a')
        .replace(/(<([^>]+)>)/gi, ' ')
        .trim()
}
