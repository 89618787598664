import { castDecimal } from 'lib/utils/castDecimal'
import { formatNumber } from 'lib/utils/formatNumber'

type Props = {
    amountInvoiced: number
    amountPaid: number
}

export const getPercentagePaidDisplay = ({ amountInvoiced, amountPaid }: Props) => {
    if (!amountInvoiced || !amountPaid) return '0%'

    const percent = castDecimal(amountPaid)
        .dividedBy(castDecimal(amountInvoiced))
        .times(100)
        .toNumber()
    const formattedPercent = formatNumber(percent, 0)

    return `${formattedPercent}%`
}
