import { castDecimal } from 'lib/utils/castDecimal'

type Props = {
    amountInvoiced: number
    amountPaid: number
}

export const getAmountRemaining = ({ amountInvoiced, amountPaid }: Props) => {
    if (!amountInvoiced) return 0
    if (!amountPaid) return amountInvoiced

    const amountRemaining = castDecimal(amountInvoiced)
        .minus(castDecimal(amountPaid))
        .toNumber()

    return amountRemaining < 0 ? 0 : amountRemaining
}
