import { Card } from 'ds/components/Card'
import { useMemo, useState } from 'react'
import { PeriodicSummary } from './PeriodicSummary'
import { AmountDueByCustomer } from './AmountDueByCustomer'
import { InvoicesDue } from './InvoicesDue'
import { AmountDueByProductCategory } from './AmountDueByProductCategory'
import { getDefaultDateRange } from 'lib/utils/getDateRangeOptions'
import { SelectDateRange } from 'ds/components/SelectDateRange'
import { Option, Select } from 'ds/components/Select'
import { useLocalStorage } from 'usehooks-ts'

type Props = {
    currency: string | undefined
    organization_id: number | null
}

export const PeriodicKpis = ({ currency, organization_id }: Props) => {
    const [dateParam, setDateParam] = useLocalStorage(
        'time_invoice_periodic_kpi',
        'time_due'
    )
    const defaultDateRangeOption = useMemo(() => getDefaultDateRange(), [])
    const [dateRange, setDateRange] = useState(defaultDateRangeOption)
    const { start, end } = dateRange

    return (
        <>
            <div className="row">
                <div className="col-12 stacked-md">
                    <div className="inline inline-md">
                        <SelectDateRange
                            label="Periodo"
                            className="stacked-md"
                            value={dateRange.value}
                            onChange={(option) => {
                                setDateRange({
                                    label: option.label,
                                    value: option.value,
                                    start: option.start,
                                    end: option.end,
                                })
                            }}
                        />
                    </div>
                    <div className="inline">
                        <Select
                            label="Ver por"
                            value={dateParam}
                            className="stacked-md"
                            onChange={setDateParam}
                        >
                            <Option value="time_invoice">Fecha de venta</Option>
                            <Option value="time_due">Fecha de vencimiento</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <PeriodicSummary
                        periodLabel={dateRange.label}
                        organization_id={organization_id}
                        currency={currency}
                        dateParam={dateParam}
                        start={start}
                        end={end}
                    />
                </div>
                <div className="col-xl-4">
                    <AmountDueByCustomer
                        organization_id={organization_id}
                        currency={currency}
                        dateParam={dateParam}
                        start={start}
                        end={end}
                    />
                </div>

                <div className="col-xl-4">
                    <AmountDueByProductCategory
                        organization_id={organization_id}
                        dateParam={dateParam}
                        currency={currency}
                        start={start}
                        end={end}
                    />
                </div>
            </div>

            <Card>
                <InvoicesDue
                    dateParam={dateParam}
                    start={start}
                    end={end}
                    organization_id={organization_id}
                    currency={currency}
                />
            </Card>
        </>
    )
}
