const getError = {
    required: (value) => {
        if (!value) {
            return 'Este campo es requerido.'
        }
    },
    email: (value) => {
        if (!value) {
            return 'Email es requerido.'
        }
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        const matches = emailRegex.test(value)
        if (!matches) {
            return 'Formato invalido.'
        }
    },
    date: (value) => {
        const isValidDate =
            value &&
            Object.prototype.toString.call(value) === '[object Date]' &&
            !isNaN(value)

        if (!isValidDate) return 'Fecha invalida.'
    },
    number: (value, min, max) => {
        if (isNaN(value)) return 'Debe ser un numero.'

        if (min && Number(value) < min) {
            return `Debe ser mayor que ${min}`
        }
        if (max && Number(value) > max) {
            return `Debe ser menor que ${max}`
        }
    },
    items: ({ items }) => {
        const errors = items.map((item) => {
            let error = []

            // Skip validation if the item is marked as deleted
            if (item.current_state === 'deleted') return false

            const canExcludeProduct = !!item.invoice_id && !item.product_id

            if (!item.product_id && !canExcludeProduct) error.push('Producto')
            if (!item.description_template) error.push('Descripción')
            if (!item.quantity) error.push('Cant.')
            if (!item.unit_price && item.unit_price !== 0) error.push('Precio')
            if (
                item.property_tax_account &&
                !String(item.property_tax_account).match('^[a-zA-Z0-9_]*$')
            )
                error.push('Cuenta predial debe ser solo caracteres alfa-numericos')

            if (error.length === 0) {
                return false
            } else {
                return `Errores en campos: ${error.join(', ')}.`
            }
        })

        if (errors.every((el) => el === false)) return false
        else return errors
    },
    organizationCfdi: ({ organization }) => {
        const errors = []
        if (!organization.legal_name) errors.push('nombre legal')
        if (!organization.tax_id) errors.push('RFC')
        if (!organization.tax_system) errors.push('regimen fiscal')
        if (!organization.address.zip) errors.push('codigo postal')
        if (!organization.address.street) errors.push('calle')
        if (!organization.address.exterior) errors.push('número exterior')
        if (!organization.csd_cer_file) errors.push('certificado csd')
        if (!organization.csd_key_file) errors.push('llave privada csd')
        if (!organization.csd_key_pw) errors.push('contraseña csd')
        if (errors.length === 0) return false

        return `Faltan campos requeridos: ${errors.join(', ')}.`
    },
    csd_files: ({ csd_files }) => {
        const keyFound = csd_files.find((file) => file.includes('.key'))
        const cerFound = csd_files.find((file) => file.includes('.cer'))
        if (!keyFound && !cerFound) {
            return `Favor de subir ambos .key y .cer.`
        }
        if (!cerFound) {
            return `Favor de subir el archivo .cer.`
        }
        if (!keyFound) {
            return `Favor de subir el archivo .key.`
        }
        return false
    },
}

export { getError }
