const colors = {
    'primary-500': '#2f81f7',
    'danger-500': '#f85149',
    'success-500': '#238636',
    'success-300': '#2eae46',
    // blue: '#2f81f7',
    red: '#f85149', // #dc3545
    // bluelight: '#3d8bfd', // #0f69ff, #006FEF
    // greenlight: '#33BB89', // #198754 in sass
    // red: '#eb0f29',
    'neutral-0': '#fff',
    'neutral-000': '#fafafa',
    'neutral-100': '#f8f9fa',
    'neutral-200': '#e9ecef',
    'neutral-300': '#dee2e6',
    'neutral-500': '#adb5bd',
    'neutral-600': '#6c757d',
    'neutral-700': '#495057',
    'neutral-400': '#ced4da',
    'neutral-800': '#343a40',
    'neutral-900': '#212325',
    'neutral-999': '#111111',
}

export { colors }
